.dx-icon:before {
  font-family: DXIcons !important;
}

.dx-datagrid-group-opened,
.dx-datagrid-group-closed,
.dx-expand,
.dx-checkbox-icon,
.dx-header-filter {
  font-family: DXIcons !important;
}

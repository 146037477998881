@import url('https://rsms.me/inter/inter.css');

body,
html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px; /* root font-size */
}

* {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre {
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(148, 148, 148);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}